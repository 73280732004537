import { t } from '@ping/helpers';
import { useDesktopDevice } from '@ping/hooks';
import { Modal, QrCode } from '@ping/uikit';

import style from './style.module.scss';

interface ISignDeclarationModalProps {
  signatureLink: string;
}

export const SignDeclarationModal = (props: ISignDeclarationModalProps) => {
  const isDesktop = useDesktopDevice();

  if (isDesktop) {
    return (
      <Modal.Root defaultOpen>
        <Modal.Portal className={style['sign-deceleration-modal']}>
          <Modal.Content className={style['sign-deceleration-modal__content']}>
            <Modal.Header className={style['sign-deceleration-modal__header']}>
              <Modal.Title className={style['sign-deceleration-modal__title']}>{t('Sign Declaration')}</Modal.Title>
              <Modal.Subtitle className={style['sign-deceleration-modal__subtitle']}>
                {t('Scan QR code with your CorePass app and sign the declaration in order to continue')}
              </Modal.Subtitle>
            </Modal.Header>

            <div className={style['sign-deceleration-modal__contrast-outer']}>
              <div className={style['sign-deceleration-modal__contrast-inner']}>
                <QrCode
                  className={style['sign-deceleration-modal__qr-code']}
                  value={decodeURIComponent(props.signatureLink)}
                />
              </div>
            </div>
          </Modal.Content>
        </Modal.Portal>
      </Modal.Root>
    );
  }

  return (
    <Modal.Root defaultOpen>
      <Modal.Portal className={style['corepass-modal']}>
        <Modal.Header className={style['corepass-modal__header']}>
          <Modal.CloseButton className={style['corepass-modal__close']} />
        </Modal.Header>

        <Modal.Content className={style['corepass-modal__content']}>
          <Modal.Title className={style['corepass-modal__title']}>
            {t('Sign form in CorePass by clicking the button bellow')}
          </Modal.Title>

          <p className={style['corepass-modal__description']}>
            {t('To be the most trusted place to buy and sell cryptocurrency and other assets, we utilize')}&nbsp;
            <a className={style['corepass-modal__link']} href='https://corepass.net'>
              {t(' CorePass - decentralized digital identity')}
            </a>
            &nbsp;
            {t(
              'to protect your personal data, secure communications and data transactions, and to comply with the EU’s General Data Protection Regulation (GDPR).'
            )}
          </p>
        </Modal.Content>

        <Modal.Actions className={style['corepass-modal__actions']}>
          <a className={style['corepass-modal__redirect']} href={props.signatureLink}>
            {t('Open CorePass')}
          </a>
        </Modal.Actions>
      </Modal.Portal>
    </Modal.Root>
  );
};
