import clsx from 'clsx';

import { t } from '@ping/helpers';

import style from './style.module.scss';

interface IExternalCorePassAddressVerificationAlertProps {
  className?: string;
}

export function ExternalCorePassAddressVerificationAlert(props: IExternalCorePassAddressVerificationAlertProps) {
  const { className } = props;
  return (
    <div className={clsx(style['external-core-pass-address-verification-alert'], className)} role='alert'>
      <div>
        <h5 className={style['external-core-pass-address-verification-alert__title']}>
          {t('This address is not eligible for withdrawals.')}
        </h5>
        <p className={style['external-core-pass-address-verification-alert__description']}>
          {t(
            'XCB and CTN withdrawals to external addresses (outside Ping) are restricted to your CorePass address used during Ping account creation. Withdrawals to other Ping addresses remain unrestricted.'
          )}
        </p>
      </div>
    </div>
  );
}
