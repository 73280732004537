import { useState } from 'react';
import { useRouter } from 'next/router';

import { VerifyAddressWithSignedFormResult } from '@ping/api';
import ArrowDownIcon from '@ping/assets/Icon/arrow-down.svg';
import { t } from '@ping/helpers';
import { Card, ScrollButton } from '@ping/uikit';
import { NotificationEvents, useNotificationWS } from '@ping/websockets';
import { DepositsNotificationStatus } from '@ping/websockets/useNotificationWS';

import Wallet from './assets/wallet.svg';
import { SignDeclarationModal } from './SignDeclarationModal';
import { SignFormLTC } from './SignFormLTC';
import { SignSuccessModal } from './SignSuccessModal';

interface IFormLTC {
  verifyWalletData: VerifyAddressWithSignedFormResult;
  onDone: () => void;
}

export const FormLTC = ({ verifyWalletData, onDone }: IFormLTC) => {
  const router = useRouter();
  const [isSignDeclarationModalVisible, setIsSignDeclarationModalVisible] = useState(false);
  const [isSignSuccessModalVisible, setIsSignSuccessModalVisible] = useState(true);
  const { notificationData, notificationCallBack } = useNotificationWS();
  notificationCallBack?.(NotificationEvents.OnWalletVerificationWithSignedForm);

  const handleDismiss = () => {
    setIsSignSuccessModalVisible(false);
    onDone?.();
  };

  const startWithdrawal = () => {
    handleDismiss();
    router.push({
      pathname: '/user/portfolio',
      query: {
        modal: 'send_receive',
        tab: 'send',
      },
    });
  };

  return (
    <>
      <Card.Root>
        <Card.Header>
          <Card.LeadingIcon>
            <Wallet />
          </Card.LeadingIcon>
          <Card.Title>{t('Verify Wallet')}</Card.Title>
        </Card.Header>

        <Card.Divider />

        <Card.Body aria-orientation='vertical'>
          <SignFormLTC verifyWalletData={verifyWalletData} onAgree={() => setIsSignDeclarationModalVisible(true)}>
            <ScrollButton token='data-sign-form-ltc-scroll-to'>
              <ArrowDownIcon />
              {t('Scroll to end')}
            </ScrollButton>
          </SignFormLTC>
        </Card.Body>
      </Card.Root>

      {isSignDeclarationModalVisible && notificationData?.status !== DepositsNotificationStatus.Success && (
        <SignDeclarationModal signatureLink={verifyWalletData.link} />
      )}

      {isSignSuccessModalVisible && notificationData?.status === DepositsNotificationStatus.Success && (
        <SignSuccessModal onContinue={startWithdrawal} onDismiss={handleDismiss} />
      )}
    </>
  );
};
