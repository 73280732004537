import { t } from '@ping/helpers';
import { Button, ButtonA11Y } from '@ping/uikit';
import clsx from 'clsx';
import style from './style.module.scss';

interface IExternalWalletAddressReplaceProps {
  className?: string;
  onClick?: () => void;
}

export function ExternalWalletAddressReplace(props: IExternalWalletAddressReplaceProps) {
  const { className, onClick } = props;
  return (
    <div className={clsx(className, style['external-wallet-address-verification-alert'])} role='alert'>
      <div>
        <h5 className={style['external-wallet-address-verification-alert__title']}>
          {t('This address is not eligible for withdrawals.')}
        </h5>
        <p className={style['external-wallet-address-verification-alert__description']}>
          {t(
            'Withdrawals to external wallets (outside Ping) are limited to one verified address per asset. Would you like to replace your current verified external ETH address with this one?'
          )}
        </p>
      </div>

      <div className={style['external-wallet-address-verification-alert__action']}>
        <ButtonA11Y className={style['external-wallet-address-verification-alert__more']}>{t('Learn more')}</ButtonA11Y>
        <Button uppercase onClick={onClick}>
          {t('Replace address')}
        </Button>
      </div>
    </div>
  );
}
