import { t } from '@ping/helpers';
import { Button, Divider } from '@ping/uikit';

import style from './style.module.scss';
import { VerifyAddressWithSignedFormResult } from '@ping/api';

interface ISignFormLTCProps {
  children: React.ReactNode;
  onAgree?: () => void;
  verifyWalletData: VerifyAddressWithSignedFormResult;
}

export const SignFormLTC = ({ verifyWalletData, onAgree, children }: ISignFormLTCProps) => {
  const currentDate = new Date();
  const formattedDate = `${String(currentDate.getDate()).padStart(2, '0')} / ${String(
    currentDate.getMonth() + 1
  ).padStart(2, '0')} / ${currentDate.getFullYear()}`;

  return (
    <section className={style['sign-form-ltc']}>
      <div className={style['sign-form-ltc__wrap']}>
        <h3 className={style['sign-form-ltc__title']} aria-details='sign-form-ltc-required sign-form-ltc-warning'>
          {t('Declaration of Ownership of Litecoin Wallet')}
        </h3>

        <p className={style['sign-form-ltc__description']} id='sign-form-ltc-required'>
          {t('Declaration is')}&nbsp;
          <strong className={style['sign-form-ltc__emphasis']} tabIndex={0}>
            {t('required to be signed with CorePass')}
          </strong>
          {t('. Please read it thoroughly and agree in order to proceed:')}
        </p>
      </div>

      <Divider />

      <dl className={style['sign-form-ltc__information']}>
        <div className={style['sign-form-ltc__wrap']}>
          <dd className={style['sign-form-ltc__information-value']}>
            {t(
              'In accordance with the Travel Rule, the contracting partner hereby declares that the person listed below is the beneficial owner of the assets and wallet. To comply with these requirements, you are required to sign the declaration below.'
            )}
          </dd>
        </div>

        <div className={style['sign-form-ltc__information-group']}>
          <div className={style['sign-form-ltc__wrap']}>
            <dt className={style['sign-form-ltc__information-title']} tabIndex={0}>
              {t('Full name / Entity:')}
            </dt>
            <dd className={style['sign-form-ltc__information-value']}>{verifyWalletData?.fullName}</dd>
          </div>

          <div className={style['sign-form-ltc__wrap']}>
            <dt className={style['sign-form-ltc__information-title']} tabIndex={0}>
              {t('Full name (English) / Entity:')}
            </dt>
            <dd className={style['sign-form-ltc__information-value']}>{verifyWalletData?.fullNameEnglish}</dd>
          </div>

          <div className={`${style['sign-form-ltc__wrap']} ${style['sign-form-ltc__wrap--row']}`}>
            <div>
              <dt className={style['sign-form-ltc__information-title']} tabIndex={0}>
                {t('Date of birth')}
              </dt>
              <dd className={style['sign-form-ltc__information-value']}>
                {verifyWalletData?.dateOfBirth.replaceAll('-', ' / ')}
              </dd>
            </div>

            <div>
              <dt className={style['sign-form-ltc__information-title']} tabIndex={0}>
                {t('CoreID')}
              </dt>
              <dd className={style['sign-form-ltc__information-value']}>{verifyWalletData?.coreId}</dd>
            </div>
          </div>
        </div>

        <div className={style['sign-form-ltc__confirmations']}>
          <span className={style['sign-form-ltc__information-title']}>Litecoin Wallet Address</span>
          <span className={style['sign-form-ltc__information-value']}>{verifyWalletData?.walletAddress}</span>
          <p className={style['sign-form-ltc__information-value']}>
            {t('I confirm that this wallet address belongs to me, and I have full control over it.')}
          </p>

          <p className={style['sign-form-ltc__information-value']}>
            {t(
              'I confirm that this transaction was executed voluntarily, without any external coercion, pressure, or third-party influence.'
            )}
          </p>

          <p className={style['sign-form-ltc__information-value']}>
            {t(
              'I acknowledge that providing false information regarding this transaction may result in the suspension of my account and may lead to legal action in accordance with Ping Exchange policies and applicable regulations.'
            )}
          </p>

          <p className={style['sign-form-ltc__information-value']}>
            {t(
              'I understand that once the withdrawal is processed, Ping Exchange holds no liability for the funds, and I assume full responsibility for the transaction and its security.'
            )}
          </p>
        </div>

        <div className={style['sign-form-ltc__footer']}>
          <div className={style['sign-form-ltc__wrap']}>
            <dt className={style['sign-form-ltc__information-title']} tabIndex={0}>
              {t('Date')}
            </dt>
            <dd className={style['sign-form-ltc__information-value']}>{formattedDate}</dd>
          </div>

          <Button type='primary' design='general' size='large' onClick={onAgree} data-sign-form-ltc-scroll-to>
            {t('AGREE & SIGN')}
          </Button>
        </div>

        {children}
      </dl>
    </section>
  );
};
