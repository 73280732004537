import { t } from '@ping/helpers';
import { Button, ButtonA11Y } from '@ping/uikit';
import clsx from 'clsx';
import style from './style.module.scss';

interface IExternalWalletAddressVerificationAlertProps {
  className?: string;
  onClick?: () => void;
}

export function ExternalWalletAddressVerificationAlert(props: IExternalWalletAddressVerificationAlertProps) {
  const { className, onClick } = props;
  return (
    <div className={clsx(className, style['external-wallet-address-verification-alert'])} role='alert'>
      <div>
        <h5 className={style['external-wallet-address-verification-alert__title']}>
          {t('The provided wallet address requires verification of ownership')}
        </h5>
        <p className={style['external-wallet-address-verification-alert__description']}>
          {t(
            'To comply with regulations, withdrawals of XCB and CTN are restricted to verified CorePass addresses. To enable withdrawals to the address you provided, please verify it using the proof of ownership verification process in your CorePass app.'
          )}
        </p>
      </div>

      <div className={style['external-wallet-address-verification-alert__action']}>
        <ButtonA11Y className={style['external-wallet-address-verification-alert__more']}>{t('Learn more')}</ButtonA11Y>
        <Button uppercase onClick={onClick}>
          {t('Verify ownership')}
        </Button>
      </div>
    </div>
  );
}
