import { t } from '@ping/helpers';
import { Button, ButtonA11Y } from '@ping/uikit';
import clsx from 'clsx';
import style from './style.module.scss';

interface IExternalWalletAddressVerificationLTCAlertProps {
  className?: string;
  onClick?: () => void;
}

export function ExternalWalletAddressVerificationLTCAlert(props: IExternalWalletAddressVerificationLTCAlertProps) {
  const { className, onClick } = props;
  return (
    <div className={clsx(className, style['external-wallet-address-verification-alert'])} role='alert'>
      <div>
        <h5 className={style['external-wallet-address-verification-alert__title']}>
          {t('This address is not eligible for withdrawals.')}
        </h5>
        <p className={style['external-wallet-address-verification-alert__description']}>
          {t(
            'Withdrawals of Bitcoin (BTC), Ethereum (ETH) and Litecoin (LTC) are limited to one verified external wallet (outside Ping) per each asset. To enable withdrawals to the external address you provided, please verify it using the proof of ownership verification process in your CorePass app or in Ping Exchange (Litecoin only).'
          )}
        </p>
      </div>

      <div className={style['external-wallet-address-verification-alert__action']}>
        <ButtonA11Y className={style['external-wallet-address-verification-alert__more']}>{t('Learn more')}</ButtonA11Y>
        <Button uppercase onClick={onClick}>
          {t('Verify ownership')}
        </Button>
      </div>
    </div>
  );
}
