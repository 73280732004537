import CheckIcon from '@ping/assets/Icon/circle-check.svg';
import { t } from '@ping/helpers';
import { ButtonA11Y, Modal } from '@ping/uikit';

import style from './style.module.scss';

interface ISignSuccessModal {
  onDismiss: () => void;
  onContinue: () => void;
}

export function SignSuccessModal(props: ISignSuccessModal) {
  return (
    <Modal.Root defaultOpen onOverlayPress={props.onDismiss}>
      <Modal.Portal className={style['sign-success-modal']}>
        <Modal.Content>
          <CheckIcon className={style['sign-success-modal__icon']} />
          <Modal.Title className={style['sign-success-modal__title']}>
            {t('Your Wallet is Ready for Withdrawals')}
          </Modal.Title>
          <Modal.Subtitle className={style['sign-success-modal__description']}>
            {t(
              'Your external Litecoin wallet address has been successfully linked with Ping. You can now initiate withdrawals to this address.'
            )}
          </Modal.Subtitle>
        </Modal.Content>

        <Modal.Actions className={style['sign-success-modal__actions']}>
          <ButtonA11Y onPress={props.onContinue} className={style['sign-success-modal__actions-button']}>
            {t('Start a Withdrawal')}
          </ButtonA11Y>
        </Modal.Actions>
      </Modal.Portal>
    </Modal.Root>
  );
}
