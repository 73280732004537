import { t } from '@ping/helpers';
import { Text, Button } from '@ping/uikit';
import VerifyOwnershipIcon from '@ping/assets/Icon/verify-ownership.svg';
import CircleWarningIcon from '@ping/assets/Icon/circle-warning.svg';
import Link from 'next/link';

import style from './style.module.scss';
import clsx from 'clsx';

interface IVerifyOwnershipProps {
  onSubmit: () => void;
  className?: string;
  isLoading?: boolean;
}

export const VerifyOwnership = ({ isLoading, onSubmit, className }: IVerifyOwnershipProps) => {
  return (
    <div className={clsx(style['verifyOwnership'], className)}>
      <div className={style['verifyOwnership__content']}>
        <VerifyOwnershipIcon />
        <Text heading='4'>
          {t('Verify ownership of your external wallet address in CorePass for BTC, ETH and LTC withdrawals')}
        </Text>
        <Text cadetBlueColor body='regular'>
          {t(
            'In order to continue, please make sure you’ve completed the proof of ownership verification process in your CorePass for the wallet address you wish to enable withdrawals for.'
          )}
        </Text>
        <ul>
          <li>
            <Text body='semi-bold'>1. {t('Open CorePass ')}</Text>
            <Text body='regular' cadetBlueColor>
              {t('to purchase a Proof of Ownership Verification voucher and follow the steps.')}
            </Text>
          </li>
          <li>
            <Text body='semi-bold'>2. {t('Return here ')}</Text>
            <Text body='regular' cadetBlueColor>
              {t('after completing the verification process in CorePass and click the button bellow to continue.')}
            </Text>
          </li>
        </ul>
        <section className={style['verifyOwnership__notice']}>
          <span className={style['verifyOwnership__notice-icon']}>
            <CircleWarningIcon />
          </span>
          <div>
            <Text body='semi-bold' className={style['verifyOwnership__notice-heading']}>
              {t('Important notice')}
            </Text>
            <Text cadetBlueColor caption='regular'>
              {t(
                'Please be aware that each asset (Bitcoin, Ethereum, Litecoin) requires a unique withdrawal address as they operate on different blockchains, necessitating separate verification for each.'
              )}
            </Text>
            <div className={style['verifyOwnership__notice-link']}>
              <Link href='/user/tiers'>{t('Learn more about supported wallets')}</Link>
            </div>
          </div>
        </section>
      </div>
      <Button
        className={style['verifyOwnership__button']}
        type='primary'
        design='general'
        size='small'
        uppercase
        htmlType='submit'
        isLoading={isLoading}
        onClick={onSubmit}
      >
        {t('Continue')}
      </Button>
    </div>
  );
};
