import { QRCodeSVG } from 'qrcode.react';
import { useEffect } from 'react';

import { t } from '@ping/helpers';
import { useCountdown } from '@ping/hooks';
import { DateTime, getDuoNumber } from '@ping/utils';

import style from './style.module.scss';
import { Loading } from '@ping/uikit';

interface ILinkExternalWalletAddressProps {
  link?: string;
  defaultTime?: number;
  onFinish?: () => void;
}

export function LinkExternalWalletAddress({ link, defaultTime, onFinish }: ILinkExternalWalletAddressProps) {
  const timer = useCountdown(defaultTime || DateTime.milliseconds(0));

  useEffect(() => {
    if (timer.minutes === 0 && timer.seconds === 0) {
      onFinish?.();
    }
  }, [timer.minutes, timer.seconds]);

  return (
    <div className={style['link-external-wallet-address']} role='region'>
      <h4 className={style['link-external-wallet-address__title']}>
        {t('Link your external wallet address with Ping')}
      </h4>
      <p className={style['link-external-wallet-address__description']}>
        {t(
          `Scan the QR code below with your CorePass app to share your newly verified wallet address with Ping. This step will enable future withdrawals from Ping to this address.`
        )}
      </p>

      <div className={style['link-external-wallet-address__box']}>
        <div className={style['link-external-wallet-address__refresh']}>
          <span className={style['link-external-wallet-address__refresh-label']}>{t('QR code refresh in')}</span>&nbsp;
          <span className={style['link-external-wallet-address__refresh-timer']}>
            {getDuoNumber(timer.minutes)}:{getDuoNumber(timer.seconds)}
          </span>
        </div>
        <div className={style['link-external-wallet-address__space']}>
          {(timer.minutes > 0 || timer.seconds > 0) && (
            <QRCodeSVG size={304} value={decodeURIComponent(link)} level='L' />
          )}
          {timer.minutes === 0 && timer.seconds === 0 && (
            <div className={style['link-external-wallet-address__loading']}>
              <Loading />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
